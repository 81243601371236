import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import 'preline'

createApp(App)
.use(router)
.use(Toast)
.mount('#app')
