import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'checking',
    component: () => import(/* webpackChunkName: "about" */ '../components/CheckingSessionView.vue')
  },
  
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "about" */ '../components/pages/AuthentificationView.vue')
  },

  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/inscription',
    name: 'inscription',
    component: () => import('../components/pages/InscriptionView.vue')
  },
  {
    path: '/tribus',
    name: 'tribuliste',
    component: () => import('../views/TribuTableView.vue'),
  },
  {
    path: '/tribus/detail/:id',  // Vous pouvez définir un chemin spécifique pour le détail de la tribu
    name: 'tribuDetail',
    component: () => import('../views/TribuDetailView.vue'),
    props: true
  },
  {
    path: '/departement',
    name: 'departementliste',
    component: () => import('../views/DepartementTableView.vue')
  },
  {
    path: '/culte',
    name: 'culteliste',
    component: () => import('../views/CulteTableView.vue')
  },
  {
    path: '/evenement',
    name: 'evenementliste',
    component: () => import('../views/EvenementTableView.vue')
  },
  {
    path: '/academie',
    name: 'academieliste',
    component: () => import('../views/AcademieTableView.vue')
  },
  {
    path: '/utilisateur',
    name: 'utilisateurliste',
    component: () => import('../views/UtilisateurTableView.vue')
  },
  {
    path: '/membre',
    name: 'membreliste',
    component: () => import('../views/MembreTableView.vue')
  },
  {
    path: '/responsable',
    name: 'responsableliste',
    component: () => import('../views/ResponsableTableView.vue')
  },
  {
    // path: '/about',
    // name: 'about',
    // // route level code-splitting
    // // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
